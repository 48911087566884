import { Route, Routes } from 'react-router-dom';
import { Home } from '../screens/home';
import { Services } from '../screens/services/index';
import { About } from '../screens/about';

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/services" element={<Services />}></Route>
      <Route path="/about" element={<About />}></Route>

    </Routes>
  );
}

export default AppRoutes;
