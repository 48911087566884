import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import './App.css';
import AppRoutes from './routes/AppRoutes';

function App() {
  return (
    <React.Fragment>
      <AppRoutes />
    </React.Fragment>
  );
}

export default App;
