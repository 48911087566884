import { Button, Col, Container, Row } from 'react-bootstrap';
import { LogoGroup } from './Header';
import './footer.css';

import phoneIcon from '../assets/icons/phone.svg';
import whatsappIcon from '../assets/icons/whatsapp.svg';
import locateIcon from '../assets/icons/location.svg';
import { useNavigate } from 'react-router-dom';

export const Footer = () => {
  const navigate = useNavigate()
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col lg={6} md={12} className='footer-left'>
            <LogoGroup />
            <p className='tagline'>
              Unlock Your Radiance, <br />
              Embrace Aesthetic Brilliance.
            </p>
          </Col>
          <Col lg={6} md={12} className='footer-right'>
            <div className="social-link-group">
              <Button className="social-link" href='https://goo.gl/maps/iWZRWx9NVc7uL6V77' target='_blank'>
                <img src={locateIcon} alt="Visit us" />
              </Button>
              <Button className="social-link" href='tel:+918123149955' target='_blank'>
                <img src={phoneIcon} alt="Call us" />
              </Button>
              <Button className="social-link" href='https://wa.me/918123149955' target='_blank'>
                <img src={whatsappIcon} alt="Whatsapp us" />
              </Button>
            </div>

            <div className="contact">
              <p>P-40/1 third floor, Ramasri plaza <br />
                Ramanashree California layout, Ananthpura road <br />
                Yelahanka, Bangalore 560064</p>
              <p>
                <a href="tel:+918123149955">8123149955</a>
              </p>
            </div>

          </Col>
        </Row>
        <p className='copyright'>
          Copyright © {new Date().getFullYear()} <a href="/"><b>Star Belle's Aesthetic Clinic</b></a>. All rights reserved<br /> Made with 🤍 by <a
            href="https://nullcrew.tech/" target="_blank"><b>NullCrew Technologies</b></a>
        </p>
      </Container>
    </div>
  );
};
export default Footer;
