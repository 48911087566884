import { Container } from 'react-bootstrap';
import skinCareImage from '../../assets/img/services/service-1.png';
import laserHairImage from '../../assets/img/services/service-2.png';
import clearUpImage from '../../assets/img/services/service-3.png';
import hairCareImage from '../../assets/img/services/service-4.png';
import dentistryImage from '../../assets/img/services/service-5.jpg';
import artistryImage from '../../assets/img/services/service-6.jpg';
import ceuticalsImage from '../../assets/img/services/service-7.jpg';
import { Header } from '../../components/Header';

import { ServicesCardGroup } from '.././../components/cards/service-card';

// * Importing icons

import Footer from '../../components/Footer';
import { ServiceComponent } from '../../components/sections/service';
import { VisitUs } from '../../components/visit';
import './index.css';

export const Services = () => {
  const servicesContent = [
    {
      id: 'skin-care',
      title: 'Skin Care Services',
      image: skinCareImage,
      content:
        'Discover our comprehensive range of skincare solutions, meticulously designed to elevate your beauty journey. Our expert team offers personalized treatments that go beyond expectations, delivering exceptional results for a flawless complexion.',
      subServices: [
        'Acne',
        'Scar',
        'Hyper-pigmentation',
        'Dark circles',
        'Anti-ageing',
        'Skin Radiance',
      ]
    },
    {
      id: 'laser-hair-removal',
      title: 'Laser Hair Removal',
      image: laserHairImage,
      content: 'Unleash the freedom of smooth skin with our advanced laser hair removal solutions. Our expert team delivers precise and effective treatments for long-lasting results. Experience hassle-free hair reduction tailored to your needs.',
      subServices: [
      ]
    },
    {
      id: 'clear-up',
      title: 'Clear-up',
      image: clearUpImage,
      content:
        `Reveal your skin's true potential with our transformative clear-up solutions. Our dedicated team offers targeted treatments to address blemishes, acne, and uneven skin tone. Experience a renewed complexion as we help you achieve clear and radiant skin.`,
      subServices: [
      ]
    },
    {
      id: 'hair-care',
      title: 'Hair Care Solution',
      image: hairCareImage,
      content:
        `Indulge in our comprehensive hair care solutions designed to revitalize and nourish your locks. Our expert team delivers customized treatments tailored to your hair type and concerns. Experience the transformation as we restore your hair's health, shine, and beauty.`,
      subServices: [
        'PRP',
        'PRF',
        'GFC',
        'Micro-needling',
        'Derma roller',
        'Hair-transplant',
      ]
    },
    {
      id: 'dentistry',
      title: 'Dentistry',
      image: dentistryImage,
      content:
        `Elevate your oral health and achieve a stunning smile with our comprehensive dentistry solutions. Our skilled team delivers personalized treatments and exceptional results, ensuring your comfort throughout the process.`,
      subServices: [
        'Veneer',
        'Aligners',
        'Braces Treatment',
        'Tooth Extraction',
        'Tooth Whitening',
        'Root Canal',
        'Implant',
        'Laser Dentistry',
      ]
    },
    {
      id: 'artistry',
      title: 'Artistry',
      image: artistryImage,
      content:
        `Embark on a journey of artistic expression with our exceptional artistry solutions. Experience the transformative power of art as we guide you through the process, creating masterpieces that captivate and inspire.`,
      subServices: [
        'Scalp Micropigmentation',
        'Eyebrow Microblading',
        'Lip Blush / Pigmentation Correction'
      ]
    },
    {
      id: 'ceuticals',
      title: 'Ceuticals',
      image: ceuticalsImage,
      content:
        `Discover the synergy of science and beauty with our innovative ceuticals. Our advanced formulas are designed to deliver targeted skincare benefits with high precision.  Unlock the secrets to ageless beauty with our cutting-edge products.`,
      subServices: [
        'Cosmonueticals',
        'Neutraceuticals',
      ]
    },
  ]

  return (
    <>
      <Header />

      {/* Our Services */}
      <section className="services">
        <Container>
          <h1 className="heading text-center">Our Services</h1>
          <p className="text-center heading-subtext">
            Let us help you reveal your hidden charm,
            <br />
            with our aesthetic skills and a little arm.
            <br />
            We'll sculpt and shape with our artistic grace,
            <br />
            and leave you feeling like a work of art in this place!
          </p>
          <p className='text-center text-sm text-translucent mt-4'>Click on a service below to know more.</p>

          <ServicesCardGroup />

        </Container>
      </section>

      <Container>
        {
          servicesContent.map(service => {
            return (
              <ServiceComponent props={service} />
            )
          })
        }
      </Container>

      <VisitUs />

      <Footer />
    </>
  );
};
