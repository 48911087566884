import { Button } from "react-bootstrap";

export const CTAButton = ({
    text, icon, href
}: {
    icon?: string;
    text: string;
    href: string;
}) => {
    return <Button className="btn btn-green cta-button" href={href} target="_blank">
        {icon ? <img src={icon} alt="Locate us" className='locate-icon' /> : null}
        {text}
    </Button >
}