import { Col, Container, Row } from "react-bootstrap"
import { CTAButton } from "./buttons"
import locateIcon from '../assets/icons/location.svg';
import phoneIcon from '../assets/icons/phone.svg';
import whatsappIcon from '../assets/icons/whatsapp.svg';


export const VisitUs = () => {
    return <section className="appointment" id="visit">
        <Container>
            <h1 className="heading text-center">
                Make a date with beauty, book now!
            </h1>
            <Row>
                <Col lg={6} className='appointment-left'>
                    <h5 className='text-center'>Come over for a touch of aesthetic magic! <br /> Visit us, we're located at</h5>
                    <p className='text-sm text-center text-translucent'>P-40/1 third floor, Ramasri plaza <br />
                        Ramanashree California layout, Ananthpura road <br />
                        Yelahanka, Bangalore 560064</p>
                    <CTAButton text='Locate us' icon={locateIcon} href='https://goo.gl/maps/iWZRWx9NVc7uL6V77' />
                </Col>
                <Col lg={6} className='appointment-right'>
                    <h5 className='text-center'>Or let us assist you to <br />
                        book an appointment with us</h5>

                    <p className='text-sm text-center text-translucent'>We're available to chat or speak to you<br />
                        9 AM - 5 PM</p>

                    <div className="cta-group">
                        <CTAButton text='Call us' icon={phoneIcon} href='tel:+918123149955' />
                        <CTAButton text='Chat with us' icon={whatsappIcon} href='https://wa.me/918123149955' />
                    </div>

                </Col>
            </Row>
        </Container>
    </section>
}