import { Col, Row } from "react-bootstrap"

export const ServiceComponent = ({ props }: {
    props: {
        id: string;
        title: string,
        image: string,
        content: string;
        subServices: string[]
    }
}) => {
    const { image, content, subServices, title, id } = props

    return <div className="service-container" id={id}>
        <div className="service-left" id="skin-care">
            <img className="service-img" src={image} />
        </div>

        <div className="service-right">
            <p className="pre-heading">STAR BELLE'S</p>
            <h1>{title}</h1>
            <p className="service-content">
                {content}
            </p>
            <Row>
                {/* <div className="sub-services"> */}
                {subServices.map(service => {
                    return (
                        // <div className="dot-container">
                        <Col lg={6} md={12} className="dot-container">
                            <div className="dot"></div>
                            <div className="dot-text">{service}</div>
                            {/* </div> */}
                        </Col>
                    )
                })}
                {/* </div> */}
            </Row>
        </div>
    </div>
}