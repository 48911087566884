import { Button, Col, Container, Row } from 'react-bootstrap';
import './index.css';

// * Importing images

import Footer from '../../components/Footer';
import { Header } from '../../components/Header';
import { AboutImageGroup } from '../../components/about-img-group';
import {
    ServicesCardGroup
} from '../../components/cards/service-card';
import { VisitUs } from '../../components/visit';

export const Home = () => {
    return (
        <>
            {/* Space for header */}
            <Header />

            {/* Main section */}
            <section className="main accent-bg">
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div className="main-left">
                                <div>
                                    <h1>Looks don't matter?</h1>
                                    <h4>We think they do</h4>
                                </div>
                                <p>
                                    Beauty is our game, let us enhance your fame. <br />
                                    With our expert care, your radiance will never be the same!
                                </p>
                                {/* <div className="main-cta">
                                    <CTAButton text='Try us' href='#visit' />
                                </div> */}
                                <Button className="btn btn-lg btn-green main-cta" href='#visit'>Try us</Button>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="main-right">
                                <AboutImageGroup />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Our Services */}
            <section className="services">
                <Container>
                    <h1 className="heading text-center">Our Services</h1>
                    <p className="text-center heading-subtext">
                        {/* Unleash the beauty within, let us be your canvas. <br />
                        Experience transformation like never before, with our artistic finesse! */}
                        Feeling drab? Let us give you some fab!
                        <br />
                        From head to toe, we'll make you glow.
                    </p>

                    <ServicesCardGroup />
                </Container>
            </section>

            {/* Appointment / Book now */}
            <VisitUs />

            {/* Footer */}
            <Footer />
        </>
    );
};
