import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/img/sb-logo.svg';
import './header.css';
import { useNavigate } from 'react-router-dom';

export const LogoGroup = () => {
  const navigate = useNavigate()
  return <div className="header-logo-group" onClick={() => { navigate('/') }}>
    <div>
      <img src={logo} className="logo" alt="Logo" />
    </div>
    <div className="logo-section">
      <div className="logo-main">Star Belle's</div>
      <div className="logo-subtext">AESTHETIC CLINIC</div>
    </div>
  </div>
}

export const Header = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className='header' variant='dark'>
      < Container className='header-container' >
        <Navbar.Brand href="/">
          <LogoGroup />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto nav">
            <Nav.Link className='nav-item' href="/">Home</Nav.Link>
            {/* <Nav.Link className='nav-item active' href="/">Home</Nav.Link> */}
            <NavDropdown className='nav-item' title="Services" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="/services#skin-care">
                Skin Care Solution
              </NavDropdown.Item>
              <NavDropdown.Item href="/services#laser-hair-removal">
                Laser Hair Removal
              </NavDropdown.Item>
              <NavDropdown.Item href="/services#clear-up">
                Clear-up
              </NavDropdown.Item>
              <NavDropdown.Item href="/services#hair-care">
                Hair Care Solution
              </NavDropdown.Item>
              <NavDropdown.Item href="/services#dentistry">
                Dentistry
              </NavDropdown.Item>
              <NavDropdown.Item href="/services#artistry">
                Artistry
              </NavDropdown.Item>
              <NavDropdown.Item href="/services#ceuticals">
                Ceuticals
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className='nav-item' href="/about">About</Nav.Link>
            {/* <Nav.Link className='nav-item' href="/contact">Contact</Nav.Link> */}

          </Nav>
        </Navbar.Collapse>
      </Container >
    </Navbar >
  );
}
