import { Container } from "react-bootstrap"
import { Header } from "../../components/Header"
import Footer from "../../components/Footer"
import { AboutImageGroup } from "../../components/about-img-group"
import './index.css'

import imageDrSwathi from '../../assets/img/dr-swathi.jpg'
import imageDrShruthi from '../../assets/img/dr-shruthi.jpg'
import { VisitUs } from "../../components/visit"

export const About = () => {
    return (
        <>
            <Header />

            <section className="about-us">
                <Container>
                    <div className="about-flex">
                        <h1 className="heading text-center">About us</h1>
                        <p className="text-center mt-4">
                            {/* </p> */}
                            At Star Belle's Aesthetic Clinic, we take pride in our ability to harmoniously blend advanced technology and medical expertise, resulting in personalized solutions that cater to your unique health and wellness needs. With a team of highly qualified, certified, and experienced doctors, we are dedicated to providing you with safe and effective treatments that yield remarkable results, empowering you to look and feel your absolute best.
                        </p>
                        <p className="text-center mt-4">

                            Located in Yelahanka, Bengaluru, our clinic operates as a registered firm, adhering to the highest standards of quality and professionalism. We are committed to offering our esteemed clients nothing less than the very best in aesthetics. Through our comprehensive range of services, we strive to redefine the boundaries of beauty, helping you achieve your desired aesthetic goals while ensuring your utmost satisfaction and well-being.
                        </p>
                        <p className="text-center mt-4 mb-5">
                            Step into our welcoming space and let our expert team guide you on a transformative journey, where your aspirations become our inspiration. Discover the synergy of advanced techniques, state-of-the-art technology, and a personalized approach that sets us apart. At Star Belle's Aesthetic Clinic, we are dedicated to unlocking your inner radiance and empowering you to embrace the beauty that lies within.
                        </p>



                        {/* <p className="mb-5">At Star Belle's Aesthetic Clinic, we blend advanced technology and medicine to create personalized solutions for your health and wellness. Our qualified, certified, and experienced doctors offer safe and effective treatments, so you can look and feel your best. Based in Yelahanka, Bengaluru, we're a registered firm committed to bringing our clients the best in aesthetics.</p> */}
                        <AboutImageGroup />
                    </div>

                </Container >
            </section >

            <hr />

            <section className="dr-swathi">
                <Container>
                    <div className="doctor-group">
                        <div className="doctor-left">
                            <img src={imageDrSwathi} alt="Dr. Swathi Bhat" />
                        </div>
                        <div className="doctor-right">
                            <h1>Dr. Swathi Bhat</h1>
                            <p className="pre-heading">
                                FOUNDER AND DIRECTOR OF STAR BELLE'S AESTHETIC CLINIC
                            </p>
                            <p className="py-5">
                                A passionate Maxillo-facial surgeon expertise in aesthetic medicine, lasers and hair transplant surgery.
                            </p>
                            <p><b>Qualification</b></p>
                            <p>
                                MDS (Maxillofacial surgeon) (RGUHS) <br />
                                FHT (ISHR) <br />
                                FFA, FMMP (UK)
                            </p>
                        </div>
                    </div>
                </Container>
            </section>
            <VisitUs />

            <Footer />

        </>
    )
}