import mainImage1 from '../assets/img/home/main-1.png';
import mainImage2 from '../assets/img/home/main-2.png';
import mainImage3 from '../assets/img/home/main-3.png';
import mainImage4 from '../assets/img/home/main-4.png';

export const AboutImageGroup = () => {
    return (
        <div className="about-img-group">

            <div className="semi-img">
                <img src={mainImage1} alt="" />
                <img src={mainImage2} alt="" />
            </div>
            <img src={mainImage3} alt="" />
            <img src={mainImage4} alt="" />
        </div>
    )
}