
// * Importing icons
import artistryIcon from '../../assets/icons/artistry.svg';
import ceuticalsIcon from '../../assets/icons/ceuticals.svg';
import dentistryIcon from '../../assets/icons/dentistry.svg';
import hairCareIcon from '../../assets/icons/hair-care.svg';
import laserHairRemovalIcon from '../../assets/icons/laser-hair-removal.svg';
import skinCareIcon from '../../assets/icons/skin-care.svg';

export const ServiceCard = ({ title, icon, href }: {
  title: string,
  icon: string,
  href: string;
}) => {
  return (
    <div className="service-card" onClick={() => {
      window.location.href = href
    }}>
      <div className="service-card-main">
        <div className="service-card-top">
          <img className="service-icon" src={icon} alt="" />
        </div>
        <p>{title}</p>
      </div>
    </div>
  );
};

export const ServicesCardGroup = () => {
  return <div className="service-card-group">
    <div className="service-card-subgroup">
      <ServiceCard
        href="/services#skin-care"
        title="Skin Care Solution"
        icon={skinCareIcon}
      />
      <ServiceCard
        href="/services#laser-hair-removal"
        title="Laser Hair Removal"
        icon={laserHairRemovalIcon}
      />
      <ServiceCard
        href="/services#clear-up"
        title="Clear-up"
        icon={skinCareIcon}
      />
      <ServiceCard
        href="/services#hair-care"
        title="Hair Care Solution"
        icon={hairCareIcon}
      />
      <ServiceCard
        href="/services#dentistry"
        title="Dentistry"
        icon={dentistryIcon}
      />
      <ServiceCard
        href="/services#artistry"
        title="Artistry"
        icon={artistryIcon}
      />
      <ServiceCard
        href="/services#ceuticals"
        title="Ceuticals"
        icon={ceuticalsIcon}
      />
    </div>
  </div>
}